.home-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	height: calc(100vh - 200px);
	align-items: center;
	justify-items: center;

	.home-data-box-container {
		display: flex;
		width: 50%;
		height: 50%;
		border: 2px solid #455979;
		border-radius: 20px;
		padding: 30px;
		box-sizing: border-box;
		flex-direction: column;

		.home-data-box-title {
			font-size: 20px;
			font-weight: bold;
		}

		.home-data-box-value {
			font-size: 90px;
			font-weight: bold;
			text-align: center;
			color: #2D65BD;
		}
	}
}