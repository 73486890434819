.roles-container {
	display: grid;
	grid-template-columns: 250px 250px 1fr;

	.role-title {
		font-weight: bold;
		font-size: 14px;
	}

	.role-field {
		padding-top: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid #B9BEC0 !important;
	}
}

