
.main-app-container {
	background-repeat: no-repeat;
	background-size: 70% 85%;
	background-position: center center;
	display: flex;
	height: 100vh;
	width: 100vw;
	flex-direction: column;
	align-items: center;
}

.homepage-logo {
	padding: 100px 0 50px;
	width: 130px
}

.email-row {
	padding-top: 330px;
	font-size: 12px;
}

.signin-box-wrapper {
	position: absolute;
	top: 200px;
	z-index: 10;
}

.signin-box {
	position: absolute;
	top: 0;
	background-color: #fff;
	transform: translateX(-50%);
	width: 540px;
	height: 300px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}

.logo-icon {
	position: absolute;
	top: 220px; // start at the top of the box - add box height and remove half icon + 10
	left: -330px; // -540/2 - 140/2 (start in the middle of the box - remove half box and another half icon + 10
	width: 140px;
}
