/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio:0) {
	input[type='range'] {
		overflow: hidden;
		width: 100%;
		outline: none;
		-webkit-appearance: none;
		background-color: #fff;
		height: 12px;
		border-radius: 4px;
		border: 1px solid #fff;
	}

	input[type='range']::-webkit-slider-runnable-track {
		width: 50px;
		height: 10px;
		-webkit-appearance: none;
		color: #13bba4;
		margin-top: -1px;
	}

	input[type='range']::-webkit-slider-thumb {
		width: 0;
		height: 0;
		-webkit-appearance: none;
		cursor: ew-resize;
		background: #434343;
		box-shadow: -800px 0 0 800px #4D5BA9;
	}

}
/** FF*/
input[type="range"]::-moz-range-thumb {
	border: 0;
	background-color: transparent;
}
input[type="range"]::-moz-range-progress {
	background-color: #4D5BA9;
	height: 12px;
}
input[type="range"]::-moz-range-track {
	background-color: #fff;
	height: 10px;
}
/* IE*/
input[type="range"]::-ms-fill-lower {
	background-color: #4D5BA9;
}
input[type="range"]::-ms-fill-upper {
	background-color: #fff;
}
