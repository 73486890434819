$mobile:  "(max-width: 765px)";
$tablet:  "(min-width: 766px) and (max-width: 1023px)";
$desktop: "(min-width: 1024px)";


$link: #465bab;
$menuBg: #071623;
$appBg: #F7FAFC;
$mainBg: #F8FAFC;
$mainTitle: #000000;
$appBlue: #4070AE;
$appGreen: #8AC93D;
$appYellow: #feee96;
$lightGreen: #AAFFAA;
$lightBlue: #a5f2ff;
$glowingBlue: #15d5f7;
$glowingGreen: #00ffc7;
$headerIcons: #ffffff;
$buttonGreen: #8AC93D;
$appDisabled: #dddddd;
$lightText: #ddf3f6;
$disabledText: #455465;
$webPageBg: #1a4d73d1;
$transparent: transparent;

$headerHeight: 80px;
$pageTitleHeight: 80px;

* {
	font-family: 'Arial';
	font-size: 13px;
}

.font2 {
	font-family: 'Arial';
}

.fontSize10 {
	font-size: 10px;
}

.fontSize11 {
	font-size: 11px;
}

.fontSize12 {
	font-size: 12px;
}

.fontSize13 {
	font-size: 13px;
}

.fontSize14 {
	font-size: 14px;
}

.fontSize15 {
	font-size: 15px;
}

#outer-container {
	background-color: $appBg;
}

.errorText {
	padding: 5px;
	font-size: 14px;
	color: red;
}

body {
	margin: 0;
	padding: 0;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
	monospace;
}

a, a:visited {
	color: #30A9B8;
	text-decoration: none;
}

option {
	@extend .font2;
}

.extended-table-row-container {
	display: flex;
	background: #113045;
	padding: 20px;
	border: 1px solid #0c424b;
	border-left-width: 0;
	border-right-width: 0;
	min-height: 150px;
}

body::-webkit-scrollbar {
	width: 10px;
}

body::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
	background-color: $glowingGreen;
	outline: 1px solid slategrey;
}


.container {
	margin: auto;
	width: 50%;
}
.ReactTags__tag {
	@extend .font2;
	@extend .fontSize14;
}

.ReactTags__tags {
	position: relative;
	display: flex;
	align-items: flex-start;
}

.timepicker, .timePicker {
	width: 60px !important;
	text-align: center;
}

select, video {
	user-select: none !important;
}

.flexRow {
	display: flex;
	align-items: center;
}

/* Styles for the input */
.ReactTags__tagInput {
	width: 270px;
	border-radius: 2px;
	display: inline-block;
}

.react-tags-wrapper {
	border: 1px solid #B9BEC0;
	border-radius: 4px;
	background-color: #ffffff;
	align-items: flex-end;
	overflow: hidden;
	width: 100%;
}

.popup-footer {
	height: 50px;
	background-color: #eee;
	border-top: 1px solid #ddd;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0 20px;
}

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
	@extend .font2;
	@extend .fontSize14;
	height: 31px;
	margin: 0;
	width: 100%;
	border: 0;
	padding-bottom: 2px;
	padding-left: 10px;
	outline: none;
}

/* Styles for selected tags */
.ReactTags__selected {
	outline: none;
	max-width: 500px;
}

.ReactTags__selected span.ReactTags__tag {
	@extend .fontSize14;
	border: 1px solid #ddd;
	background: #eee;
	display: inline-block;
	padding: 5px;
	margin: 3px 5px;
	border-radius: 2px;
}
.ReactTags__selected a.ReactTags__remove {
	color: #aaa;
	margin-left: 5px;
	cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
	position: absolute;
}
.ReactTags__suggestions ul {
	list-style-type: none;
	box-shadow: .05em .01em .5em rgba(0,0,0,.2);
	background: white;
	width: 200px;
}
.ReactTags__suggestions li {
	border-bottom: 1px solid #ddd;
	padding: 5px 10px;
	margin: 0;
}
.ReactTags__suggestions li mark {
	text-decoration: underline;
	background: none;
	font-weight: 600;
}
.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
	background: #b7cfe0;
	cursor: pointer;
}

.ReactTags__remove {
	border: none;
	cursor: pointer;
	background: none;
}

.tag {
	background-color: #EFF6F8;
	border: 1px solid #B9BEC0;
	border-radius: 4px;
	height: 30px;
	display: flex;
	align-items: center;
	padding: 0 5px;
	margin-right: 10px;
}

.tag:hover {
	color: white !important;
	background-color: black !important;
}

.react-confirm-alert-body > * {
	font-size: 16px;
	text-align: right;
}

.react-confirm-alert-body {
	font-size: 20px;
	direction: rtl;
	text-align: right;
}

.react-confirm-alert-button-group {
	direction: ltr;
}

.react-confirm-alert-overlay {
	z-index: 9999;
}

.Toastify__toast-body {
	direction: rtl;
}